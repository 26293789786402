// Navbar.js
import React, { useState } from "react";
import "./style.css";
import { Avatar, Dropdown, Input, Menu, Space } from "antd";
import {
  SearchOutlined,
  DownOutlined,
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate, NavLink } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import logo from "../../assets/images/logo.jpeg";
import { Link } from "react-scroll";

const Navbar = ({ user }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    // navigate("/auth");
  };

  const menu = (
    <Menu>
      {/* <Menu.Item
        key="profile"
        onClick={goToProfile}
      
      >
        Profile & Settings
      </Menu.Item> */}
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const studySetMenu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={() => navigate("/study-sets/create")}
        icon={<BiCopy size={20} />}
      >
        Study sets
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <nav className={`navbar ${menuOpen ? "open" : ""}`}>
        <div className="logo">
          <img src={logo} alt="logo" height={60} width={50} />
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? (
            <CloseOutlined style={{ fontSize: "22px" }} />
          ) : (
            <MenuOutlined style={{ fontSize: "22px" }} />
          )}
        </div>
        <ul className={`navbar-menu ${menuOpen ? "open" : "close"}`}>
          <li>
            <NavLink to="/about">ABOUT ME</NavLink>
          </li>

          <li>
            <Link to="training" smooth={true} style={{ cursor: "pointer" }}>
              TRAINING
            </Link>
          </li>

          <li>
            <NavLink to="/reviews">REVIEWS</NavLink>
          </li>

          <li>
            <NavLink to="/contact-us">CONSULT</NavLink>
          </li>

          <li>
            <div className="navbar-register-btn">
              <NavLink to="/login">
                <button className="global-btn">Login</button>
              </NavLink>
            </div>
          </li>

          {/* {user ? (
            <li className="onMobile">
              <Dropdown overlay={studySetMenu} trigger={["click"]}>
                <AiFillPlusCircle
                  size={38}
                  color="rgb(15, 97, 134)"
                  cursor="pointer"
                />
              </Dropdown>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Avatar
                  size="large"
                  style={{ cursor: "pointer" }}
                  icon={<UserOutlined />}
                />
              </Dropdown>
            </li>
          ) : ( */}
          <li>
            <button className="global-btn onMobile">Login</button>
          </li>
          {/* )} */}
        </ul>

        {/* {user ? (
          <div className="profile-dropdown">
            <Dropdown overlay={studySetMenu} trigger={["click"]}>
              <AiFillPlusCircle
                size={38}
                color="rgb(15, 97, 134)"
                cursor="pointer"
              />
            </Dropdown>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Avatar
                size="large"
                style={{ cursor: "pointer" }}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </div>
        ) : ( */}

        {/* )} */}
      </nav>
    </>
  );
};

export default Navbar;
