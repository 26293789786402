import React from "react";
import Navbar from "../components/navbar/index";
import Footer from "../components/footer/index";
import Contactus from "../components/contactus/index";

import useWindowDimensions from "../utils/getWindowDimenssions";
import { Col, Form, Input, Row, Spin, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { AuthAPI } from "../api";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  const { width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onHandleSubmit = async (values) => {
    console.log(values);
    navigate("/program");
    // let res = await AuthAPI.login(values);
    // if (res.status == 200) {
    //   navigate("/program");
    // } else {
    //   message.error({
    //     type: "error",
    //     content: "Incorrect email or password!",
    //   });
    // }
    // setLoader(false);
  };
  return (
    <>
      <Navbar user={user} width={width} />
      <Content className="contactus-main">
        <div data-aos="fade-up">
          <div className="title">Login to your account!</div>
          <Row className="contactus-form" justify="center" align="middle">
            <Col xs={22} md={8}>
              <Form onFinish={onHandleSubmit} className="form">
                <div>
                  <div className="form-title">Email</div>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "required!" },
                      { type: "email", message: "invalid email" },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="example@gmail.com"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div>
                  <div className="form-title">Password</div>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "required!" }]}
                  >
                    <Input
                      type="password"
                      placeholder="password"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>

                <br />
                {loader ? (
                  <div style={{ margin: "1rem 2rem" }}>
                    <Spin />
                  </div>
                ) : (
                  <button className="global-btn">Login</button>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default Login;
