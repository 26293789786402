import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create({
  //   baseURL: `http://44.195.60.58:4002/api`,

  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     "cache-control": "no-cache",
  //   },
});

instance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data?.message) {
      toast.success(response.data?.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("user");
    }

    if (error.response?.data?.message) {
      toast.error(error.response.data?.message);
    } else if (error?.message) {
      toast.error(error?.message);
    } else if (error.response?.data?.error) {
      toast.error(error.response.data.error);
    }
    return error;
  }
);

export default instance;
