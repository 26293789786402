import axios from "../axiosConfig";

class Routes {
  contactus(data) {
    return axios.post("/auth/contact-us", data);
  }

  applyToProgram(data) {
    return axios.post("/auth/apply-program", data);
  }
  login(data) {
    return axios.post("/auth/login", data);
  }
}

export default new Routes();
