import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Link } from "react-router-dom";
import {
  BsYoutube,
  BsFacebook,
  BsInstagram,
  BsTiktok,
  BsLinkedin,
  BsX,
  BsTwitter,
} from "react-icons/bs";
import "./style.css";

const Index = () => {
  return (
    <Content className="footer-main">
      <Row justify="space-evenly">
        <Col xs={24} md={9}>
          <div>© Copyright by Ronnie Cantu. All Rights Reserved.</div>
          <div className="links">
            <Link style={{ color: "red", fontWeight: "500" }} to="/contact-us">
              CONTACT US
            </Link>
            <Link
              style={{ color: "red", fontWeight: "500" }}
              to="/privacy-policy"
            >
              PRIVACY POLICY
            </Link>
            <Link style={{ color: "red", fontWeight: "500" }} to="/term-of-use">
              TERM OF USE
            </Link>
          </div>
        </Col>
        <Col xs={24} md={9} style={{ textAlign: "center" }}>
          <div className="social-media">
            <a
              href="https://www.facebook.com/ronnie.cantu.3?mibextid=LQQJ4d"
              target="_blank"
            >
              <BsFacebook size={22} cursor="pointer" />
            </a>
            <a href="https://www.instagram.com/ronnie_cantu/" target="_blank">
              <BsInstagram size={22} cursor="pointer" />
            </a>
            <a
              href="https://www.linkedin.com/in/ronnie-cantu-924a94130"
              target="_blank"
            >
              <BsLinkedin size={22} cursor="pointer" />
            </a>
            <a
              href="https://x.com/ronnie_cantu?s=11&t=VcWx-1az5axEKZZpbrQlpg"
              target="_blank"
            >
              <BsTwitter size={22} cursor="pointer" />
            </a>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Index;
