import { Col, Form, Input, Row, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthAPI } from "../../api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const onHandleSubmit = async (values) => {
    if (captcha) {
      setLoader(true);
      let res = await AuthAPI.contactus(values);
      if (res.status == 200) {
        setLoader(false);
        Swal.fire(
          "Thanks for Submitting!",
          "We will contact you shortly",
          "success"
        );
        navigate("/");
      }
    } else {
      message.error({
        type: "error",
        content: "Please Fill the Captcha",
      });
    }
  };
  return (
    <Content className="contactus-main">
      <div data-aos="fade-up">
        <div className="title">GET IN TOUCH WITH US</div>
        <Row className="contactus-form" justify="center" align="middle">
          <Col xs={22} md={13}>
            <Form onFinish={onHandleSubmit} className="form">
              <Row justify="space-between">
                <Col xs={24} md={8}>
                  <div>
                    <div className="form-title">First Name</div>
                    <Form.Item
                      name="firstName"
                      rules={[{ required: true, message: "required!" }]}
                    >
                      <Input placeholder="First name" autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div>
                    <div className="form-title">Last Name</div>
                    <Form.Item
                      name="lastName"
                      rules={[{ required: true, message: "required!" }]}
                    >
                      <Input placeholder="Last Name" autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div>
                <div className="form-title">Email</div>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "required!" },
                    { type: "email", message: "invalid email" },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="example@gmail.com"
                    autoComplete="off"
                  />
                </Form.Item>
              </div>
              <div>
                <div className="form-title">Message</div>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "required!" }]}
                >
                  <TextArea
                    placeholder="Enter Your Message"
                    autoComplete="off"
                    rows={3}
                    className="textArea"
                  />
                </Form.Item>
              </div>
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPCHA_SITE_KEY}
                  onChange={(e) => setCaptcha(e)}
                />
              </div>
              <br />
              {loader ? (
                <div style={{ margin: "1rem 2rem" }}>
                  <Spin />
                </div>
              ) : (
                <button className="global-btn">Send</button>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Index;
