import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer/index";
import useWindowDimensions from "../utils/getWindowDimenssions";
import { Collapse } from "antd";
const { Panel } = Collapse;

let week1Plan = [
  {
    Monday:
      "Sparring 4 rounds, 3 rounds heavy bag, 3 rounds slip back, speed bag, jump rope 10 minutes",
  },
  {
    Tuesday:
      "5 rounds shadow boxing, one 5 minute round (increase every week), 2 rounds water bag, 3 double end. 20 minute jog in evening",
  },
  {
    Wednesday:
      "Sparring 4 rounds, 3 rounds heavy bag, 3 rounds slip bag, speed bag, jump rope 10 minutes. 20 minute jog in evening",
  },
  {
    Thursday:
      "Drill day. 6 rounds straight of heave bag/double end. That’s it for day",
  },
  {
    Friday:
      "Sparring 4 rounds, 3 rounds heavy bag, 3 rounds slip bag, speed bag, jump rope 10 minutes. 25 minute jog at night",
  },
  {
    Saturday:
      "Some type of long version of cardio. Outdoor run, stairmaster, bike, or swim for 45 minutes",
  },
];

let week4Plan = [
  {
    Monday:
      "Sparring 6 rounds, 4 rounds heavy bag, 2 rounds slip back, speed bag, jump rope 10 minutes",
  },

  {
    Tuesday:
      "5 rounds shadow boxing, one 8 minute round (increase to 9 and 10 the following weeks), 2 rounds water bag, 3 double end. 30 minute jog in evening",
  },

  {
    Wednesday:
      "Sparring 6 rounds, 4 rounds heavy bag, 2 rounds slip back, speed bag, jump rope 10 minutes",
  },

  {
    Thursday:
      "Drill day. 6 rounds straight of heavy bag/double end. That’s it for day",
  },

  {
    Friday:
      "Sparring 6 rounds, 4 rounds heavy bag, 2 rounds slip back, speed bag, jump rope 10 minutes",
  },

  { Saturday: "5 total 400M sprints. 3 total 200M sprints" },
];

export const Program = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  const { width } = useWindowDimensions();

  return (
    <div>
      <Navbar user={user} width={width} />
      <div className="programMain">
        <h2>12 Weeks Ronnie Cantu Boxing Program:</h2>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Week-1:" key="1">
            {week1Plan?.map((el, index) => {
              const day = Object.keys(el)[0]; // Get the key (e.g., "Monday")
              const plan = el[day]; // Get the value (e.g., the workout plan for Monday)
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>
                    <u>{day}:</u>
                  </h3>
                  {/* This prints the day (e.g., "Monday") */}
                  <h4>{plan}</h4> {/* This prints the plan for that day */}
                </div>
              );
            })}
          </Panel>
          <Panel header="Week-2 and 3:" key="2">
            <h3 style={{ color: "red" }}> REPEAT WEEK 1</h3>
          </Panel>
          <Panel header="Week-4" key="3">
            {week4Plan?.map((el, index) => {
              const day = Object.keys(el)[0]; // Get the key (e.g., "Monday")
              const plan = el[day]; // Get the value (e.g., the workout plan for Monday)
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>
                    <u>{day}:</u>
                  </h3>
                  {/* This prints the day (e.g., "Monday") */}
                  <h4>{plan}</h4> {/* This prints the plan for that day */}
                </div>
              );
            })}
          </Panel>
          <Panel header="Week-5 and 6:" key="4">
            <h3 style={{ color: "red" }}> REPEAT WEEK 4</h3>
          </Panel>
        </Collapse>
      </div>
      <Footer />
    </div>
  );
};
